<template>
  <div id="userOrder">
    <Dialog ref="dialog" :config="config"> </Dialog>
    <div>
      <div class="ohead">
        <div class="myInput">
          <i class="el-icon-search"></i>
          <input
            type="text"
            :placeholder="this.$fanyi('请输入关键词搜索')"
            v-model="form.keywords"
            @keydown.enter="getGoodsLists"
          />
          <button @click="getGoodsLists">{{ $fanyi("搜索") }}</button>
        </div>
        <div class="btns">
          <button @click="delGoods('', '', 'all')">
            {{ $fanyi("选中移出") }}
          </button>
        </div>
      </div>
      <div class="tallys">
        <span
          :class="item.titleChecked ? 'active' : ''"
          @click="tabBtnClick(item)"
          v-for="(item, index) in tagLists"
          :key="index"
          >{{ item.tag_name }}</span
        >
      </div>

      <div
        class="SearchConterCrosswise_public goods"
        v-if="goodsList.length != 0"
        v-loading="loading"
      >
        <div
          class="SearchConterCrosswiseCon"
          v-for="(item, index) in goodsList"
          :key="index"
        >
          <input
            :checked="item.checked"
            type="checkbox"
            name=""
            id=""
            @click="item.checked = !item.checked"
            class="check-box"
          />
          <div
            class="SearchConterCrosswiseImg"
            @click="$fun.toCommodityDetails(item.goods_id)"
          >
            <el-image :src="item.image_url">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
              <div slot="placeholder">
                <i class="el-icon-loading"></i>
              </div>
            </el-image>
          </div>
          <div class="rests">
            <p class="goodsTitle" :title="item.title">
              {{ item.title }}
            </p>
            <p class="flex acenter">
              <span
                >{{
                  $fun.EURNumSegmentation(
                    $fun.ceil(item.price * $store.state.exchangeRate)
                  )
                }}€</span
              >
              {{ $fun.RMBNumSegmentation(item.price) }}￥
              <span class="obtns">
                <el-popover placement="right" trigger="click">
                  <div @click.stop class="pop-up" v-if="item.showTagWin">
                    <p v-for="(otem, ondex) in tagLists" :key="ondex">
                      <input
                        type="checkbox"
                        :checked="otem.checked"
                        @click="
                          otem.checked = !otem.checked;
                          checkTagAdd(item.id, item);
                        "
                        name=""
                        id=""
                        class="che"
                      /><span :title="otem.tag_name">{{ otem.tag_name }}</span>
                      <img
                        @click="delTag(otem.tag_id, ondex)"
                        src="../../../../assets/newImg/shanchu.svg"
                        alt=""
                      />
                    </p>
                    <div class="addTagBtn">
                      <input
                        type="text"
                        @keydown.enter="addTag(item)"
                        v-model="addTagTxt"
                      />
                      <el-button type="primary" @click="addTag(item)">{{
                        $fanyi("添加")
                      }}</el-button>
                    </div>
                  </div>

                  <img
                    slot="reference"
                    @click.stop="goodsTagOpen(item)"
                    src="../../../../assets/user-img/shouChang.svg"
                    alt=""
                  />
                </el-popover>

                <img
                  @click="delGoods(index, item)"
                  src="../../../../assets/newImg/shanchu.svg"
                  alt=""
                />
              </span>
            </p>
          </div>
        </div>
      </div>

      <div v-else class="tishi">
        {{ $fanyi(pageMsg) }}
      </div>
      <div class="allpag">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.page"
          :page-size="form.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Dialog from "../../../../components/public/Dialog.vue";

export default {
  data() {
    return {
      pageMsg: "暂无数据",
      tagLists: [], //标签列表
      goodsList: [], //收藏商品列表
      addTagTxt: "", //添加标签的文本
      loading: true,
      total: 0,
      form: {},
      config: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("提示"),
        btnTxt: [this.$fanyi("删除"), this.$fanyi("取消")],
      }, // 弹框属性
    };
  },
  components: {
    Dialog,
  },
  computed: {},
  created() {
    this.form.pageSize = 12;
    this.getTagData();
    this.getGoodsLists();
  },
  mounted() {
    document.addEventListener("click", this.bodyCloseMenus);
  },
  methods: {
    // 删除商品
    delGoods(index, item, type) {
      var ids = [];
      this.goodsList.forEach((item) => {
        if (item.checked == true) {
          ids.push(item.id);
        }
      });
      var idsstr = ids.join(",");

      if (ids.length == 0 && type == "all") {
        return this.$message.warning(this.$fanyi("请至少选择一个商品"));
      }

      this.$refs.dialog.open(
        this.$fanyi("是否删除选中的商品"),
        () => {
          this.$api
            .favoriteGoodsDelete(
              type == "all" ? { id: idsstr } : { id: item.id }
            )
            .then((res) => {
              if (res.code != 0) return;
              if (
                Number(this.total) % Number(this.form.pageSize) == 1 &&
                this.form.page >
                  parseInt(Number(this.total) / Number(this.form.pageSize))
              ) {
                this.form.page--;
              }

              this.getGoodsLists();
              this.getTagData();
            });
        },
        () => {}
      );
    },
    // 添加商品标签
    async addTag(item) {
      this.$api
        .favoriteTagAdd({
          type: 1,
          tag_name: this.addTagTxt,
        })
        .then((res) => {
          //console.log("favoriteTagDelete", res);
          if (res.code != 0) return;
          this.addTagTxt = "";
          // 重新获取标签列表
          this.$api.favoriteTagList({ type: "1" }).then((res) => {
            //console.log("favoriteTagList", res);
            this.tagLists = res.data;
            // 调用处理标签列表事件
            this.tagDataProcessing();
            this.goodsTagOpen(item);
            this.$forceUpdate();
          });
        });
    },
    // 删除商品标签
    delTag(id, index) {
      this.$api
        .favoriteTagDelete({
          tag_id: id,
        })
        .then((res) => {
          //console.log("favoriteTagDelete", res);
          if (res.code != 0) return;
          this.tagLists.splice(index, 1);
        });
    },
    // 将商品分配到选中的标签里去
    checkTagAdd(id, item) {
      let ids = [];
      this.tagLists.forEach((item) => {
        if (item.checked) ids.push(item.tag_id);
      });
      //console.log(this.tagLists);
      ids = ids.join(",");
      let datas = {
        id: id,
      };
      if (ids) {
        datas.tag_ids = ids;
      }
      this.$api.favoriteGoodsToTags(datas).then((res) => {
        //console.log("favoriteGoodsToTags", res);
        if (res.code != 0) return;
        item.tag_ids = ids;
      });
    },
    // 全局鼠标点击事件
    bodyCloseMenus() {
      // 关闭所有商品的标签弹窗
      this.goodsList.forEach((item) => {
        item.showTagWin = false;
      });
      this.$forceUpdate();
    },
    //打开单个商品标签弹窗
    goodsTagOpen(item) {
      //console.log(item);
      // 关闭所有标签列表的弹窗
      this.goodsList.forEach((item) => {
        item.showTagWin = false;
      });
      let goodsTagIds = item.tag_ids.split(",");

      // 初始化商品标签列表数据
      this.tagLists.forEach((item) => {
        //console.log(goodsTagIds, String(item.tag_id));
        if (goodsTagIds.indexOf(String(item.tag_id)) != -1) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      });

      item.showTagWin = true;
      this.$forceUpdate();
    },
    // 标签按钮点击事件
    tabBtnClick(item) {
      // 设置选中便签的值
      item.titleChecked = !item.titleChecked;
      this.form.page = 1;
      this.getGoodsLists("tagGoods");
    },
    // 获取商品收藏夹标签列表并调用商品收藏夹列表获取函数
    getTagData() {
      this.$api.favoriteTagList({ type: "1" }).then((res) => {
        //console.log("favoriteTagList", res);
        this.tagLists = res.data;
        // 调用处理标签列表事件
        this.tagDataProcessing();
        // 调用获取商品收藏夹列表事件
      });
    },
    // 获取商品收藏夹列表
    getGoodsLists(type) {
      let datas = {
        pageSize: this.form.pageSize,
        page: this.form.page,
        keywords: this.form.keywords,
      };
      let tagIds = [];
      // 如果有筛选就循环遍历获取选中的标签id传给接口

      this.tagLists.forEach((item) => {
        if (item.titleChecked) tagIds.push(item.tag_id);
      });
      tagIds = tagIds.join(",");
      datas.tag_ids = tagIds;

      this.$api.favoriteGoodsList(datas).then((res) => {
        //console.log("favoriteGoodsList", res);
        this.loading = false;
        this.goodsList = res.data.data;
        this.total = res.data.total;
      });
      this.dataProcessing();
    },
    // 处理收藏夹标签数据
    tagDataProcessing() {
      this.tagLists.forEach((item) => {
        item.checked = false;
        item.titleChecked = false;
      });
    },
    // 处理收藏夹商品数据
    dataProcessing() {
      this.goodsList.forEach((item) => {
        item.showTagWin = false;
        item.checked = false;
      });
    },
    handleSizeChange(val) {
      this.form.page_num = val;
      this.form.page = 1;
      this.getGoodsLists();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.getGoodsLists();
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
#userOrder {
  @import "../../../../css/mixin";
  width: 100%;

  > div {
    background: #ffffff;
    border: 1px solid #e1e1e1;
    padding: 20px;

    .ohead {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .myInput {
        width: 450px;
        height: 40px;

        position: relative;
        display: flex;
        align-items: center;

        > i {
          width: 20px;
          height: 24px;
          margin: 9px 11px 9px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
        }
        > input {
          border: none;
          width: 400px;
          border-radius: 4px;
          border: 1px solid #c0c4cc;
          height: 100%;
          font-size: 14px;
          padding-left: 40px;
          padding-right: 10px;
          transition: 0.3s;
          &:focus {
            border-color: #1e2997;
          }
        }
        > button {
          width: 80px;
          height: 100%;
          background: $homePageSubjectColor;
          border-radius: 4px;
          font-size: 16px;

          color: #ffffff;
          line-height: 18px;

          position: absolute;
          right: 0;
          top: 0;
          &:hover {
            opacity: 0.7;
          }
        }
      }
      .btns {
        > button {
          width: 96px;
          height: 40px;
          background: #1e2997;
          border-radius: 4px;
          font-size: 16px;

          color: #ffffff;
        }
      }
    }
    .tallys {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 24px;
      span {
        display: block;
        height: 24px;
        border-radius: 5px;
        border: 1px solid #ededed;
        margin: 0 10px 10px 0;
        padding: 4px 8px;
        font-size: 12px;
        cursor: pointer;
        &.active {
          border: 1px solid #47bd9b;
          color: #47bd9b;
        }
      }
    }
    .goods {
      display: flex;
      flex-wrap: wrap;
      .check-box {
        width: 30px;
        height: 30px;
        background-color: #ffffff;
        border: solid 1px #dddddd;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        font-size: 0.8rem;
        margin: 0;
        padding: 0;
        position: relative;
        display: inline-block;
        vertical-align: top;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
        cursor: pointer;
        -webkit-appearance: none;
        -webkit-user-select: none;
        user-select: none;
        -webkit-transition: background-color ease 0.1s;
        transition: background-color ease 0.1s;
        &:checked {
          background: #1673ff;
        }
        &:checked::after {
          content: "";
          top: 7.5px;
          left: 7.5px;
          position: absolute;
          background: transparent;
          border: #fff solid 2px;
          border-top: none;
          border-right: none;
          height: 6px;
          width: 10px;
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
    }
    .goods.SearchConterCrosswise_public {
      gap: 15px;
      .SearchConterCrosswiseCon {
        $size: 190px;
        width: $size;
        margin: 0 0;

        .SearchConterCrosswiseImg {
          width: $size;
          height: $size;
          .el-image {
            width: $size;
            height: $size;
          }
        }
        .rests {
          padding: 10px 10px 15px;
        }
      }
    }
    .tishi {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 300px;
      font-size: 25px;
      font-weight: bold;
      color: #bcb8b8;
    }
    .allpag {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 25px 0;
    }
  }
}

.pop-up {
  width: 246px;
  background: #ffffff;

  padding: 20px;
  z-index: 2;
  align-items: center;
  p {
    display: flex;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 20px;
    }
    .che {
      width: 16px;
      height: 16px;
      background-color: #ffffff;
      border: solid 1px #dddddd;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      font-size: 0.8rem;
      margin: 0;
      margin-right: 6px;
      padding: 0;
      position: relative;
      display: inline-block;
      vertical-align: top;
      cursor: default;
      -webkit-appearance: none;
      -webkit-user-select: none;
      user-select: none;
      -webkit-transition: background-color ease 0.1s;
      transition: background-color ease 0.1s;
      &:checked {
        background: #1673ff;
      }
      &:checked::after {
        content: "";
        top: 1px;
        left: 1px;
        position: absolute;
        background: transparent;
        border: #fff solid 2px;
        border-top: none;
        border-right: none;
        height: 6px;
        width: 10px;
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
    > span {
      display: block;
      height: 16px;
      max-width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;

      color: #000000;
      line-height: 16px;
      margin-right: auto;
    }
  }
  .addTagBtn {
    position: relative;
    height: 30px;
    input {
      width: 90%;
      height: 100%;
      background: #ffffff;
      border-radius: 15px;
      border: 1px solid rgba(30, 41, 151, 0.3);
      padding: 0 12px;
      font-size: 14px;
    }
    /deep/.el-button {
      position: absolute;
      right: 0;
      top: 0;
      height: 30px;
      width: 64px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
    }
  }
}
.obtns {
  margin-left: auto;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #fbfbfbef;
  img {
    width: 20px;
    &:first-child {
      margin-right: 10px;
    }
  }
}
</style>
